import gql from 'graphql-tag';

import {
  outlineComposedContentFragment,
  previewComposedContentFragment,
  searchComposedContentFragment,
  fullComposedContentFragment,
  blueprintComposedContentFragment,
  AssessmentQuestion,
  QuestionOption,
  Event,
  Tag,
  Stream,
  Content,
  ContentProvider,
  LessonSpark,
  Word,
  WordDefinition,
  TaxonomyTag,
  MetadataTag,
  MetadataStandard
} from './schema';

// Set the maximum depth of our queries.
export const maxLevels = 10;

/**
 * Recursive function to print deep queries for content. Set composedAttachment
 * to false if the fragment already specifies how attached content should be shaped.
 * @param {string} fragmentName
 * @param {boolean} [composedAttachment=true] Default to fetching attachments with the composed fragment
 * @param {number} [currentLevel]
 * @param {string} [filterAttachments] Pass in a stream uid to filter attachments. Used by blueprint.
 * @returns {string}
 */
function deepContent (fragmentName, { composedAttachment = true, currentLevel = 1, filterAttachments } = {}) {
  let attached = '';

  if (composedAttachment && filterAttachments) {
    attached = `attached(filter: { streams: { uid_in: "${filterAttachments}" } }) { ...${fragmentName} }`;
  } else if (composedAttachment) {
    attached = `attached { ...${fragmentName} }`;
  }

  return `
    ...${fragmentName}
    ${attached}
    ${currentLevel < maxLevels
      ? `
      ...on Bundle {
        children {
          ${deepContent(fragmentName, { composedAttachment, currentLevel: currentLevel + 1, filterAttachments })}
        }
      }
    `
      : ''}
  `;
}

export const queries = {
  // Query for the current user.
  me: gql`
    query FetchCurrentUser {
      me {
        id
        username
        name
        hasWireAccess
        isStaff
        isContributor
        isWireAdmin
        groups {
          id
          name
        }
        permissions {
          editAllContent
          editAllStreams
          editLiteProductGrants
          viewAllContent
          viewAllStreams
        }
      }
    }
  `,
  // Deep content query for the outline.
  outlineContent: gql`
    query FetchOutlineContent($id: CUID!) {
      content(id: $id) {
        ...outlineComposedContent
        streams { ...outlineStream }
        ...on Bundle {
          children {
            ${deepContent('outlineComposedContent', { composedAttachment: false })}
          }
        }
      }
    }
    ${outlineComposedContentFragment}
    ${Stream.outlineFragment}
  `,
  // Shallow content query for the outline. Used when updating
  // children and attachments. This only fetches one level deep.
  shallowOutlineContent: gql`
    query FetchShallowOutlineContent($id: CUID!) {
      content(id: $id) {
        ...outlineComposedContent
        streams { ...outlineStream }
        ...on Bundle {
          children { ...outlineComposedContent }
        }
      }
    }
    ${outlineComposedContentFragment}
    ${Stream.outlineFragment}
  `,
  // Deep content query for the bundle preview.
  previewContent: gql`
    query FetchPreviewContent($id: CUID!) {
      content(id: $id) {
        ${deepContent('previewComposedContent', { composedAttachment: false })}
      }
    }
    ${previewComposedContentFragment}
  `,
  // Shallow query for previewing attachment details.
  previewAttachment: gql`
    query FetchPreviewAttachment($id: CUID!) {
      content(id: $id) {
        ...previewContent
        ...on LessonSpark {
          ...previewLessonSpark
        }
      }
    }
    ${Content.previewFragment}
    ${LessonSpark.previewFragment}
  `,
  // Deep query for minimal content. Used when generating blueprints. This query
  // is a little special, because we can include a specific stream uid to filter
  // attachments by.
  minContentBlueprint: (streamUid) => gql`
    query FetchMinContentBlueprint($id: CUID!) {
      content(id: $id) {
        ${deepContent('blueprintComposedContent', { filterAttachments: streamUid })}
      }
    }
    ${blueprintComposedContentFragment}
  `,
  // Shallow query for minimal content. Used when fetching data from the cache.
  childrenOfContent: gql`
    query FetchChildrenOfContent($id: CUID!) {
      content(id: $id) {
        id
        uid
        streams {
          id
        }
        ...on Bundle {
          children {
            id
            uid
            position
          }
        }
      }
    }
  `,
  // Shallow query for full content. Used when rendering the drawer/form for a
  // piece of content. Include the data for attached items, so they display in
  // the relevant form field.
  fullContent: gql`
    query FetchFullContent($id: CUID!) {
      content(id: $id) {
        ...fullComposedContent
        attached { ...fullComposedContent }
        ...on Bundle { children { ...fullComposedContent } }
      }
    }
    ${fullComposedContentFragment}
  `,
  // Shallow query for multiple minimal content. Returns lists of content and totals.
  // Used when searching for content in the picket and in paginated tables.
  searchContents: gql`
    query SearchContents(
      $filter: ContentFilterInput,
      $order: [ContentOrderInput!]
      $version: VersionAlias,
      $versionAt: DateTime,
      $first: Int,
      $offset: Int,
      $rawFilter: String
    ) {
      contents(
        filter: $filter,
        order: $order
        version: $version,
        versionAt: $versionAt,
        first: $first,
        offset: $offset,
        rawFilter: $rawFilter
      ) {
        ...searchComposedContent
      }

      totalContents(
        filter: $filter,
        version: $version,
        versionAt: $versionAt,
        rawFilter: $rawFilter
      )
    }
    ${searchComposedContentFragment}
  `,

  // Validate content on the server.
  contentValidity: gql`
    query contentValidity($id: CUID!, $cascadeToStreams: [UID!], $isPublishAll: Boolean) {
      contentValidity(id: $id, cascadeToStreams: $cascadeToStreams, isPublishAll: $isPublishAll) {
        hasErrors
        hasWarnings
        errors {
          name
          message
          location {
            id
            field
          }
        }
        warnings {
          name
          message
          location {
            id
            field
          }
        }
        errorIds
        warningIds
      }
    }
  `,
  // Get presigned S3 urls for file uploading.
  presignedUrl: gql`
    query GeneratePresignedUrl($type: String!, $filename: String!, $mimetype: String!) {
      presignedUrl(type: $type, filename: $filename, mimetype: $mimetype)
    }
  `,
  // Get Article snapshots, used to compare versions. It only includes the fields
  // we need to compare.
  // When using this query, always specify `fetchPolicy: 'no-cache'` to prevent
  // results from overwriting the Apollo Cache.
  articleSnapshot: gql`
    query FetchArticleSnapshot($id: CUID!, $version: VersionAlias!, $versionAt: DateTime!) {
      contentSnapshot(id: $id, version: $version, versionAt: $versionAt) {
        ...on Article {
          articleLevels {
            uid
            language
            gradeBand
            isActive
            shortTitle
            htmlTitle: title(format: TEXT_HTML)
            htmlTeaser: teaser(format: TEXT_HTML)
            htmlText: text(format: TEXT_HTML)
          }
        }
      }
    }
  `,
  // Get predicted standards from Certica.
  predictedStandards: gql`
    query FetchPredictedStandards($certicaId: String!) {
      predictedStandards(certicaId: $certicaId) {
        id
        confidenceRating
        standardTitle
        standardDescription
        region
        grades
        subject
      }
    }
  `,
  powerWords: gql`
    query FetchPowerWords($uid: UID!, $field: String!) {
      powerWords(uid: $uid, field: $field) {
        uid
        wordForm
        gradeBand
        wordDefinition {
          id
          uid
          definition
          rawDefinition: definition(format: TEXT_RAW)
        }
      }
    }
  `,
  vocabWords: gql`
    query FetchVocabWords($uid: UID!, $field: String!, $gradeBands: [GradeBand!]!) {
      vocabWords(uid: $uid, field: $field, gradeBands: $gradeBands) {
        uid
        wordForm
        gradeBand
      }
    }
  `,
  // When querying for legacy header images, we really only use rawFilter.
  // Include the other keyArgs here (they're defined in client/index.jsx)
  // so Apollo can update the cache correctly.
  legacyHeaderImages: gql`
    query FetchLegacyHeaderImages(
      $filter: ContentFilterInput,
      $order: [ContentOrderInput!]
      $version: VersionAlias,
      $versionAt: DateTime,
      $first: Int,
      $offset: Int,
      $rawFilter: String
    ) {
      contents(
        filter: $filter,
        order: $order
        version: $version,
        versionAt: $versionAt,
        first: $first,
        offset: $offset,
        rawFilter: $rawFilter
      ) {
        id
        uid
        ...on LegacyArticle {
          headerId
          headerImageUrl
        }
      }
    }
  `,
  // Shallow query for full tag. Used when rendering the drawer/form for a tag.
  tag: gql`
    query FetchFullTag($id: CUID!) {
      tag(id: $id) {
        ...fullTag
      }
    }
    ${Tag.fullFragment}
  `,
  // Shallow query for full taxonomy tag. Used when searching/displaying selected taxonomy tags
  taxonomyTag: gql`
   query FetchFullTaxonomyTag($id: Int!) {
    taxonomyTag(id: $id) {
       ...fullTaxonomyTag
     }
   }
   ${TaxonomyTag.fullFragment}
 `,
  // Get recommended tags for a piece of content.
  recommendedTags: gql`
 query FetchRecommendedTaxonomyTags($uid: UID!) {
  getRecommendedTaxonomyTags(uid: $uid) {
    id
    tagTitle
    tagType
    tagDisplayName
    origin
    hierarchy
    updatedAt
    createdAt
    name
    score
   }
 }
`,
  // Shallow query for full tag. Used when rendering the drawer/form for a metadata tag.
  metadataTag: gql`
   query FetchFullMetadataTag($id: Int!) {
    metadataTag(id: $id) {
       ...fullMetadataTag
     }
   }
   ${MetadataTag.fullFragment}
 `,
  // Minimal query for tags, used when autosuggesting tags.
  tags: gql`
    query SearchTags(
      $filter: TagFilterInput,
      $order: [TagOrderInput!]
      $first: Int,
      $offset: Int
    ) {
      tags(
        filter: $filter,
        order: $order
        first: $first,
        offset: $offset
      ) {
        ...searchTag
      }

      totalTags(
        filter: $filter
      )
    }
    ${Tag.searchFragment}
  `,
  searchTaxonomyTags: gql`
  query searchTaxonomyTags(
    $needle: String!
    $excludeIds: [String]
  ) {
    searchTaxonomyTags(
      needle: $needle,
      excludeIds: $excludeIds
    ) {
      ...searchTaxonomyTag
    }
  }
  ${TaxonomyTag.searchFragment}
`,
  searchMetadataTags: gql`
    query searchMetadataTags(
      $needle: String!
      $excludeIds: [String]
    ) {
      searchMetadataTags(
        needle: $needle,
        excludeIds: $excludeIds
      ) {
        ...searchMetadataTag
      }
    }
    ${MetadataTag.searchFragment}
  `,
  // Minimal query for standards, used when autosuggesting standards.
  searchMetadataStandards: gql`
    query SearchMetadataStandards(
      $needle: String!
      $limitToNationalStandards: Boolean,
      $excludeIds: [String]
    ) {
      searchMetadataStandards(
        needle: $needle
        limitToNationalStandards: $limitToNationalStandards
        excludeIds: $excludeIds
      ) {
        ... searchMetadataStandard
      }
    }
    ${MetadataStandard.searchFragment}
  `,
  // Shallow query for full stream. Used when rendering the drawer/form for a stream.
  stream: gql`
    query FetchFullStream($id: CUID!) {
      stream(id: $id) {
        ...fullStream
      }
    }
    ${Stream.fullFragment}
  `,
  // Minimal query for streams, used when autosuggesting streams and
  // displaying the list.
  streams: gql`
    query SearchStreams(
      $filter: StreamFilterInput,
      $order: [StreamOrderInput!]
      $first: Int,
      $offset: Int
    ) {
      streams(
        filter: $filter,
        order: $order
        first: $first,
        offset: $offset
      ) {
        ...searchStream
      }

      totalStreams(
        filter: $filter
      )
    }
    ${Stream.searchFragment}
  `,
  subjectProductStreams: gql`
  query FetchSubjectProductStreams {
    subjectProductStreams {
      ...searchStream
    }
  }
  ${Stream.searchFragment}
`,
  customStreams: gql`
  query SearchCustomStreams(
    $filter: StreamFilterInput,
    $order: [StreamOrderInput!]
    $first: Int,
    $offset: Int
  ) {
    customStreams(
      filter: $filter,
      order: $order
      first: $first,
      offset: $offset
    ) {
      ...searchStream
    }

    totalStreams(
      filter: $filter
    )
  }
  ${Stream.searchFragment}
`,
  contentProvider: gql`
  query FetchFullContentProvider($id: CUID!) {
    contentProvider(id: $id) {
      ...fullContentProvider
    }
  }
  ${ContentProvider.fullFragment}
`,
  contentProviders: gql`
    query SearchContentProviders(
      $filter: ContentProviderFilterInput,
      $order: [ContentProviderOrderInput!],
      $first: Int,
      $offset: Int
    ) {
      contentProviders(
        filter: $filter,
        order: $order,
        first: $first,
        offset: $offset
      ) {
        ...searchContentProvider
      }

      totalContentProviders(
        filter: $filter
      )
    }
    ${ContentProvider.searchFragment}
  `,
  liteProductGrants: gql`
    query SearchLiteProductGrants(
      $filter: LiteProductGrantFilterInput,
      $order: [LiteProductGrantOrderInput!],
      $first: Int,
      $offset: Int
    ) {
      liteProductGrants(filter: $filter, order: $order, first: $first, offset: $offset) {
        ...searchLiteProductGrant
      }
    }
  `,
  activeLiteProductGrants: gql`
    query SearchLiteProductGrants(
      $filter: LiteProductGrantFilterInput,
      $order: [LiteProductGrantOrderInput!],
      $first: Int,
      $offset: Int
    ) {
      activeLiteProductGrants(filter: $filter, order: $order, first: $first, offset: $offset) {
        ...searchLiteProductGrant
      }
    }
  `,
  wordDefinitions: gql`
    query SearchWordDefinitions(
      $filter: WordDefinitionFilterInput,
      $order: [WordDefinitionOrderInput!],
      $first: Int,
      $offset: Int
    ) {
      wordDefinitions(
        filter: $filter,
        order: $order,
        first: $first,
        offset: $offset
      ) {
        id
        definition
        rawDefinition: definition(format: TEXT_RAW)
      }
    }
  `,
  // Query for single assessment question.
  assessmentQuestion: gql`
    query FetchMinAssessmentQuestion($id: CUID!) {
      assessmentQuestion(id: $id) {
        ...fullAssessmentQuestion
        ...on MultipleChoice {
          options {
            ...fullQuestionOption
          }
        }
      }
    }
    ${AssessmentQuestion.fullFragment}
    ${QuestionOption.fullFragment}
  `,
  // Fetch the build timestamp from the server. If the server timestamp
  // is newer than the client timestamp, it means the client is out-of-date
  // and the user should hard-refresh to get the latest client.
  buildTimestamp: gql`
    query FetchBuildTimestamp {
      buildTimestamp
    }
  `,
  smartBundleAlgorithms: gql`
    query FetchSmartBundleAlgorithms {
      smartBundleAlgorithms {
        label
        value
        parameters {
          label
          value
          type
          description
        }
      }
    }
  `,
  articleWorkflows: gql`
    query FetchArticleWorkflows {
      articleWorkflows {
        dateArchived
        id
        name
      }
    }
  `,
  words: gql`
    query SearchWords($filter: WordFilterInput) {
      words(filter: $filter) {
        uid
        headWord
      }
    }
  `,
  publishAllStatus: gql`
    query PublishAllStatus($id: CUID!) {
      publishAllStatus(id: $id) {
        id
        type
        name
        streams {
          id
        }
      }
    }
  `,
};

export const mutations = {
  // Additive updates to content.
  setContent: gql`
    mutation SetContent($input: ContentInput!) {
      setContent(input: $input) {
        ...fullComposedContent
        attached { ...fullComposedContent }
        ...on Bundle { children { ...fullComposedContent } }
      }
    }
    ${fullComposedContentFragment}
  `,
  // Subtractive updates to content.
  unsetContent: gql`
    mutation UnsetContent($input: ContentInput!) {
      unsetContent(input: $input) {
        ...fullComposedContent
        attached { ...fullComposedContent }
        ...on Bundle { children { ...fullComposedContent } }
      }
    }
    ${fullComposedContentFragment}
  `,
  // Delete a content record.
  deleteContent: gql`
    mutation DeleteContent($uid: UID!) {
      deleteContent(uid: $uid) {
        id
      }
    }
  `,
  // Additive updates to content in the Outline.
  setOutlineContent: gql`
    mutation SetOutlineContent($input: ContentInput!) {
      setContent(input: $input) {
        ...outlineComposedContent
        attached { ...outlineComposedContent }
        ...on Bundle {
          children {
            ...outlineComposedContent
            streams {
              id
              uid
            }
          }
        }
      }
    }
    ${outlineComposedContentFragment}
  `,
  // Subtractive updates to content in the Outline.
  unsetOutlineContent: gql`
    mutation UnsetOutlineContent($input: ContentInput!) {
      unsetContent(input: $input) {
        ...outlineComposedContent
        attached { ...outlineComposedContent }
        ...on Bundle { children { ...outlineComposedContent } }
      }
    }
    ${outlineComposedContentFragment}
  `,
  // Recursively clone content on the server.
  cloneContent: gql`
    mutation CloneContent($id: CUID!, $cloneTo: CloneToInput!) {
      cloneContent(id: $id, cloneTo: $cloneTo) {
        ...fullComposedContent
        attached { ...fullComposedContent }
        ...on Bundle { children { ...fullComposedContent } }
      }
    }
    ${fullComposedContentFragment}
  `,
  // Load articles from URL.
  loadFromUrl: gql`
    mutation LoadFromURL($id: CUID!, $url: String!, $isFile: Boolean) {
      loadFromUrl(id: $id, url: $url, isFile: $isFile) {
        ...fullComposedContent
      }
    }
    ${fullComposedContentFragment}
  `,
  // Additive updates to stream.
  setStream: gql`
    mutation SetStream($input: StreamInput!) {
      setStream(input: $input) {
        ...fullStream
      }
    }
    ${Stream.fullFragment}
  `,
  // Subtractive updates to stream.
  unsetStream: gql`
    mutation UnsetStream($input: StreamInput!) {
      unsetStream(input: $input) {
        ...fullStream
      }
    }
    ${Stream.fullFragment}
  `,
  // Delete a stream record.
  deleteStream: gql`
    mutation DeleteStream($uid: UID!) {
      deleteStream(uid: $uid) {
        id
      }
    }
  `,
  // Move a child to another bundle. We're doing the setContent BEFORE the
  // unsetContent to prevent the moved child from being automatically archived
  // when it's removed from it's former parent.
  moveToBundle: gql`
    mutation MoveToBundle($uid: UID!, $source: UID!, $dest: UID!, $position: Float) {
      dest: setContent(input: {
        uid: $dest
        children: {
          uid: $uid
          position: $position
        }
      }) {
        id
        ...on Bundle {
          children { id position }
        }
      }

       source: unsetContent(input: {
        uid: $source
        children: {
          uid: $uid
        }
      }) {
        id
        ...on Bundle {
          children { id position }
        }
      }
    }
  `,

  setAssessmentQuestion: gql`
    mutation SetAssessmentQuestion($input: AssessmentQuestionInput!) {
      setAssessmentQuestion(input: $input) {
        ...fullAssessmentQuestion
        ...on MultipleChoice {
          options { ...fullQuestionOption }
        }
      }
    }
    ${AssessmentQuestion.fullFragment}
    ${QuestionOption.fullFragment}
  `,

  unsetAssessmentQuestion: gql`
    mutation UnsetAssessmentQuestion($input: AssessmentQuestionInput!) {
      unsetAssessmentQuestion(input: $input) {
        ...fullAssessmentQuestion
      }
    }
    ${AssessmentQuestion.fullFragment}
  `,

  // Deleting an assessment question will also remove it from its AssessmentLevel.
  deleteAssessmentQuestion: gql`
    mutation DeleteAssessmentQuestion($uid: UID!) {
      deleteAssessmentQuestion(uid: $uid) {
        id
      }
    }
  `,

  setContentProvider: gql`
    mutation SetContentProvider($input: ContentProviderInput!) {
      setContentProvider(input: $input) {
        ...fullContentProvider
      }
    }
    ${ContentProvider.fullFragment}
  `,

  unsetContentProvider: gql`
    mutation UnsetContentProvider($input: ContentProviderInput!) {
      unsetContentProvider(input: $input) {
        ...fullContentProvider
      }
    }
    ${ContentProvider.fullFragment}
  `,

  deleteContentProvider: gql`
    mutation DeleteContentProvider($uid: UID) {
      deleteContentProvider(uid: $uid) {
        id
      }
    }
  `,

  setTag: gql`
    mutation SetTag($input: TagInput!) {
      setTag(input: $input) {
        ...fullTag
      }
    }
    ${Tag.fullFragment}
  `,

  unsetTag: gql`
  mutation UnsetTag($input: TagInput!) {
    unsetTag(input: $input) {
      ...fullTag
    }
  }
  ${Tag.fullFragment}
  `,

  setWord: gql`
  mutation SetWord($input: WordInput!) {
    setWord(input: $input) {
      ...fullWord
    }
  }
  ${Word.fullFragment}
  `,

  setWordDefinition: gql`
  mutation setWordDefinition($input: WordDefinitionInput!) {
    setWordDefinition(input: $input) {
      ...fullWordDefinition
    }
  }
  ${WordDefinition.fullFragment}
  `,

  deleteWordDefinition: gql`
  mutation DeleteWordDefinition($uid: UID!) {
    deleteWordDefinition(uid: $uid) {
      id
    }
  }
`,

  // Manually trigger an event on a piece of content. When returning data,
  // we only care about properties that are affected by the events.
  triggerContentEvent: gql`
    mutation TriggerContentEvent(
      $id: CUID!,
      $event: ManualEvents!,
      $cascadeToStreams: [CUID!]
      $languages: [Language!]
    ) {
      triggerContentEvent(
        id: $id,
        event: $event,
        cascadeToStreams: $cascadeToStreams
        languages: $languages
      ) {
        id
        status
        updatedAt
        updatedBy {
          name
          id
        }
        events {
          ...fullEvent
        }
        error
      }
    }
    ${Event.fullFragment}
  `
};
